import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, map } from 'rxjs';
import { UserGroupInfo } from '../models';
import { env } from '../../../../../../../environments/environment';
import { LoggerService } from '../../../../../../shared/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  readonly #baseUrl: string;
  readonly #serviceName: string

  constructor(
    private readonly logger: LoggerService,
    private readonly httpClient: HttpClient
  ) {
    this.#baseUrl = `${env.API_URL}/groups`;
    this.#serviceName = UserGroupService.name
    this.logger.log(`${this.#serviceName}.constructor: baseUrl = `, `${this.#baseUrl}`);
  }

  getAllUserGroups() {
    this.logger.log(
      `${this.#serviceName}.getAllUserGroups: `,
      `${this.#baseUrl}`
    );

    return this.httpClient
      .get<UserGroupInfo[]>(`${this.#baseUrl}`)
      .pipe(first());
  }

  getAllTags() {
    this.logger.log(
      `${this.#serviceName}.getAllUserGroups: `,
      `${this.#baseUrl}`
    );

    return this.httpClient
      .get<string[]>(`${this.#baseUrl}/tags`)
      .pipe(first());
  }

  getGroupsByUsers(userEmails: string[]) {
    this.logger.debug(`${this.#serviceName}.getGroupsByUsers: userEmails=`, userEmails);
    return this.httpClient.patch(`${this.#baseUrl}/users`, userEmails, { observe: 'body' }).pipe(
      first()
    );
  }

  saveUserGroup(group: UserGroupInfo) {
    this.logger.debug(`${this.#serviceName}.updateUserGroup: group=`, group);

    return this.httpClient.post(`${this.#baseUrl}`, group, { observe: 'body' }).pipe(
      first(),
      map((_) => group)
    );
  }

  updateUserAssignment(userEmail: string, groupIds: string[]) {
    this.logger.debug(`${this.#serviceName}.updateUserAssignment: group=`, groupIds);
    return this.httpClient.patch(`${this.#baseUrl}/users/${userEmail}`, groupIds, { observe: 'body' }).pipe(
      first(),
      map((_) => groupIds)
    );
  }

  deleteUserGroup(group: UserGroupInfo) {
    this.logger.debug(`${this.#serviceName}.deleteUser: message=`);

    const url = `${this.#baseUrl}/${group.groupId}`;
    return this.httpClient.delete(url, { observe: 'body' }).pipe(
      first(),
      map((_) => group.groupId)
    );
  }
}
